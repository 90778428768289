import { React, useState, useEffect, useRef } from 'react';
import { Dropdown, Form, Button, Modal, Row, Col } from 'react-bootstrap';
import Toast from 'react-bootstrap/Toast';
import { isMobile, isMobileOnly, isTablet } from 'react-device-detect';
import { FaLock } from "react-icons/fa";
import { AutoSizer, Column, Table, CellMeasurer, CellMeasurerCache } from 'react-virtualized';
import { apiUrl } from '../../config.js';
import { useParams } from 'react-router';
import { useDeviceInfo } from '../DeviceInfo';
import './managerReport.scss';
import { object } from 'prop-types';
import LoadingSpinner from '../Loading-Component/Loading';


const ManageSecurity = () => {
    const filtervals = {
        site_name: "",
        shiftStart: "",
        shiftFinish: ""
    }
    const createshifts = {
        venueid: '',
        codb: ''
    }
    const [TopFiltervals, setTopFiltervals] = useState(filtervals);
    const [createshiftvals, setcreateshiftvals] = useState(createshifts);
    const [manageSec, setManageSec] = useState([]);
    const [rosterSummary, setRosterSummary] = useState([]);
    const { deviceInfo } = useDeviceInfo();
    const [RolesForCreate, setRolesForCreate] = useState([]);
    const [financialWeeks, setFinancialWeeks] = useState([]);
    const [loading, setLoading] = useState(false);
    const params = useParams();

    const [selectedSite, setSelectedSite] = useState(TopFiltervals.Site || 'Select Site');
    const [selectedFinancialWeek, setSelectedFinancialWeek] = useState({ description: 'Please Select' });
    const [sites, setSites] = useState([]);
    const [totalHours, setTotalHours] = useState(0);
    const [totalCost, setTotalCost] = useState(0);
    const [toastVisible, setToastVisible] = useState(false);
    const [toastTitle, setToastTitle] = useState('');
    const [toastMessage, setToastMessage] = useState('');
    const [noRosterDataMsg, setNoRosterDataMsg] = useState(null);

 



    async function GetRoles() {
        if (deviceInfo !== '') {
            setLoading(true);
            try {
                const res = await fetch(`${apiUrl}/StonegateReport/GetRolesForRoster`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    }
                });

                if (!res.ok) {
                    throw new Error('Network response was not ok');
                }

                const response = await res.json();
                setLoading(false);

                if (response.message != null) {
                    console.log(response);
                } else {
                    setRolesForCreate(response.item1);

                }
            } catch (ex) {
                console.log(ex);
                setLoading(false);
            }
        }
    }

    async function GetFinancialWeeks() {
        if (deviceInfo !== '') {
            try {
                const res = await fetch(`${apiUrl}/StonegateReport/GetFinancialWeeks`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    }
                });

                if (!res.ok) {
                    throw new Error('Network response was not ok');
                }

                const response = await res.json();

                if (response.message != null) {
                    console.log(response);
                } else {
                    setFinancialWeeks(response.item1);

                }
            } catch (ex) {
                console.log(ex);
            }
        }
    }


    async function GetRoster(StartDate, finishdate, siteName) {
        if (deviceInfo !== '') {
            setLoading(true);
            try {
                const res = await fetch(`${apiUrl}/StonegateReport/GetRoster`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ Email: params.email, DateFrom: StartDate, DateTo: finishdate, SiteName: siteName })
                });

                if (!res.ok) {
                    throw new Error('Network response was not ok');
                }

                const response = await res.json();



                if (response.message != null) {
                    console.log(response);
                } else {
                    setManageSec(response);
                    if (response.length === 0) {
                        setNoRosterDataMsg('No results found');
                    } else {
                        setNoRosterDataMsg(null);
                    }
                    setLoading(false);
                }
            } catch (ex) {
                console.log(ex);
                setLoading(false);
            }
        }
    }

    async function GetRosterSummary(StartDate, finishdate, sitename) {

        if (deviceInfo !== '') {
            setLoading(true);
            try {
                const res = await fetch(`${apiUrl}/StonegateReport/GetRosterSummary`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ Email: params.email, DateFrom: StartDate, DateTo: finishdate, SiteName: sitename })
                });

                if (!res.ok) {
                    throw new Error('Network response was not ok');
                }

                const response = await res.json();


                if (response.message != null) {
                    console.log(response);
                    setLoading(false);
                } else {
                    setRosterSummary(response);
                    setLoading(false);
                }
            } catch (ex) {
                console.log(ex);
                setLoading(false);
            }
        }
    }

    async function GetSites() {
        if (deviceInfo !== '') {
            try {
                const res = await fetch(`${apiUrl}/StonegateReport/GetSites/${params.email}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    }
                });
                
                if (!res.ok) {
                    throw new Error('Network response was not ok');
                }

                const response = await res.json();

                setSites(response);
            } catch (err) {
                console.log(err);
            }
        }
    }


    //Duplicate modal

    const handleDuplicateSelected = async (duplicateDate, checkedRows, handleCloseDupModal) => {
        setLoading(true);
        const dateToDuplicateTo = new Date(duplicateDate);
        const shiftsToDuplicate = Object.values(checkedRows).map(shift => ({
            shiftId: shift.shiftId,
            shiftStartDate: new Date(shift.shiftStart.split('T')[0]),
            codb: shift.codb
        }));

        const firstShiftDate = shiftsToDuplicate.reduce((r, o) => o.shiftStartDate < r.shiftStartDate ? o : r).shiftStartDate;
        let diffTime = dateToDuplicateTo.getTime() - firstShiftDate.getTime();
        let diffDays = Math.round(diffTime / (1000 * 3600 * 24));

        try {
            const req = shiftsToDuplicate.map(shift => ({ id: shift.shiftId, daysForward: diffDays, CODB: shift.codb }));
            const res = await fetch(`${apiUrl}/StonegateReport/stonegate__duplicateShifts`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(req)
            });

            if (!res.ok) {
                setLoading(false);
                throw new Error('Network response was not ok');
            }
            const response = await res.json();
            setToastTitle('Success');
            setToastMessage('Shifts duplicated successfully!');
            setLoading(false);
            setToastVisible(true);
            GetRoster(TopFiltervals.shiftStart, TopFiltervals.shiftFinish, selectedSite);
        } catch (err) {
            setToastTitle('Error');
            setToastMessage(`Failed to duplicate shifts: ${err.message}`);
            setLoading(false);
            setToastVisible(true);
            console.log(err);
        } finally {
            // Need to reload close modal, reload table etc.
            handleCloseDupModal();
        }
    }
    //Remove Modal 

    const handleOpenRemoveModal = async (checkedRows, handleCloseRemoveModal) => {
        setLoading(true);
        const shiftsToDuplicate = Object.values(checkedRows).map(shift => ({
            shiftId: shift.shiftId,
            codb: shift.codb
        }));

        try {
            const req = shiftsToDuplicate.map(shift => ({ RotshiftID: shift.shiftId, CODB: shift.codb }));

            const res = await fetch(`${apiUrl}/StonegateReport/stonegate__deleteShift`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(req)
            });

            if (!res.ok) {
                setLoading(false);
                throw new Error('Network response was not ok');
            }
            const response = await res.json();
            setToastTitle('Success');
            setToastMessage('Shifts removed successfully!');
            setLoading(false);
            setToastVisible(true);
            GetRoster(TopFiltervals.shiftStart, TopFiltervals.shiftFinish, selectedSite);
        } catch (err) {
            setToastTitle('Error');
            setToastMessage(`Failed to remove shifts: ${err.message}`);
            setLoading(false);
            setToastVisible(true);
            console.log(err);
        } finally {
            // Need to reload close modal, reload table etc.
            handleCloseRemoveModal();
        }
    }

    useEffect(() => {
        const currentDate = new Date();
        const threeMonthsFromNow = new Date();
        threeMonthsFromNow.setMonth(currentDate.getMonth() + 3);

        setTopFiltervals({
            site_name: "",
            shiftStart: currentDate.toISOString().split('T')[0], // Format as yyyy-mm-dd
            shiftFinish: threeMonthsFromNow.toISOString().split('T')[0] // Format as yyyy-mm-dd
        });
    
        GetSites();       
        GetRoles();
        GetFinancialWeeks();
    }, [deviceInfo, apiUrl, params.email]);


    useEffect(() => {
        if (sites.length > 0) {
            const firstSite = sites[0];
            handleSiteSelect(firstSite.site_name, firstSite.venueID, firstSite.codb);
            setcreateshiftvals(prevs => ({ ...prevs, venueid: firstSite.venueID, codb: firstSite.codb }));
        }
    }, [sites]); // This is to render the first site in the table as defualt

    const formatterfunc = new Intl.NumberFormat('en-GB', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        useGrouping: true,
    });

    useEffect(() => {
        if (TopFiltervals.site_name !== '' && TopFiltervals.site_name.length > 0) {
            const filtertablevals = manageSec.filter(item => {
                return item.site_name === TopFiltervals.site_name;
            });

            // Calculate totalHours and totalCost for the filtered values
            const totalHours = filtertablevals.reduce((sum, item) => sum + item.totalHours, 0).toFixed(2);
            const totalCost = filtertablevals.reduce((sum, item) => sum + item.totalCost, 0).toFixed(2);

            // Update the state with filtered values
            setManageSec(filtertablevals);
            
            // You can also set the totals in the state if you need to display them

            setTotalHours(totalHours);
            setTotalCost(formatterfunc.format(totalCost));
        }
    }, [TopFiltervals]);

    // useEffect(() => {
    //     setSelectedSite(TopFiltervals.Site || 'Select Site');
    // }, [TopFiltervals.Site]);






    const AddShiftRow = () => {
        const [roles, setRoles] = useState([]);
        const [date, setDate] = useState('');
        const [startTime, setStartTime] = useState('');
        const [endTime, setEndTime] = useState('');
        const [notes, setNotes] = useState('');
        const [qty, setQty] = useState(0);
        const [selectedRole, setSelectedRole] = useState('Select Role');
        const [validated, setValidated] = useState(false);

        const getFutureDate = () => {
            const today = new Date();
            const futureDate = new Date(today.setDate(today.getDate() + 14));
            return futureDate.toISOString().split('T')[0]; // Format as YYYY-MM-DD
        };

        useEffect(() => {
            const uniqueRoles = Array.from(new Set(RolesForCreate.map(item => item.Role)));
            setRoles(uniqueRoles);
        }, [RolesForCreate.length > 0]);

        const handleRoleChange = (roleItem) => {
            setSelectedRole(roleItem);
        };

        const handleSubmit = async (event) => {

            setLoading(true);

            const options = {
                day: '2-digit',
                month: '2-digit',
                year: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                hour12: false // This ensures 24-hour time format
            };

            const form = event.currentTarget;
            if (form.checkValidity() === false) {
                event.preventDefault();
                event.stopPropagation();
                setLoading(false);
            }
            setValidated(true);
            const shiftData = {
                shiftstart: new Date(`${date} ${startTime}`).toLocaleString('en-GB', options),
                shiftfinish: new Date(`${date} ${endTime}`).toLocaleString('en-GB', options),
                rolename: selectedRole,
                qty: qty,
                notes: notes,
                venueid: createshiftvals.venueid.toString(),
                CODB: createshiftvals.codb
            };

            try {
                const response = await fetch(`${apiUrl}/StonegateReport/stonegate__createShift`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(shiftData),
                });
                const data = await response.json();
                setToastTitle('Success');
                setToastMessage('Shift added successfully!');
                setLoading(false);
                setToastVisible(true);
                GetRoster(TopFiltervals.shiftStart, TopFiltervals.shiftFinish, selectedSite);
            } catch (error) {
                setToastTitle('Error');
                setToastMessage(`Failed to add shift: ${error}`);
                setLoading(false);
                setToastVisible(true);
                console.error('Error creating shift:', error);
            }
        };

        return (
            <div>
                <h4 className="ps-2 mb-0">Add Shifts</h4>
                <p className="ps-2 mb-0">*Member of staff will be allocated by security provider</p>
                <Form noValidate validated={validated} className="add-shift-row w-auto mt-1 p-2 align-items-end">
                    <div className="d-flex d-lg-block">
                        <Dropdown className="mb-3 me-3 me-lg-0 flex-grow-1" disabled>
                            <div className="d-flex align-items-center justify-content-between">
                                <Form.Label>Select Role</Form.Label>
                            </div>

                            <Dropdown.Toggle className="w-100" variant="default" id="dropdown-basic" style={{ height: 38 }}>
                                <div>{selectedRole}</div>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                {roles.map((roleItem, index) => (
                                    <Dropdown.Item key={index} onClick={() => handleRoleChange(roleItem)}>
                                        {roleItem}
                                    </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>

                        <div className="d-flex mb-3 flex-wrap">
                            <Form.Group className="me-3">
                                <Form.Label htmlFor="shift-number">No. of shifts</Form.Label>
                                <Form.Control
                                    required
                                    style={{ height: 38 }}
                                    type="number"
                                    placeholder="Number"
                                    id="shift-number"
                                    value={qty === 0 ? '' : qty}
                                    onChange={(e) => setQty(Math.abs(e.target.value))}
                                />
                            </Form.Group>

                            <Form.Group className="flex-grow-1">
                                <Form.Label htmlFor="shift-date">Date</Form.Label>
                                <Form.Control
                                    required
                                    style={{ height: 38 }}
                                    type="date"
                                    id="shift-date"
                                    value={date}
                                    min={getFutureDate()} // Maximum date exactly 14 days in the future
                                    onChange={(e) => setDate(e.target.value)}
                                />
                            </Form.Group>
                        </div>
                    </div>
                    <div className="d-flex ">
                        <Form.Group className="mb-3 me-3 flex-grow-1">
                            <Form.Label htmlFor="shift-start-time">Start Time</Form.Label>
                            <Form.Control
                                required
                                style={{ height: 38 }}
                                type="time"
                                id="shift-start-time"
                                value={startTime}
                                onChange={(e) => setStartTime(e.target.value)}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3 flex-grow-1">
                            <Form.Label htmlFor="shift-end-time">End Time</Form.Label>
                            <Form.Control
                                required
                                style={{ height: 38 }}
                                type="time"
                                id="shift-end-time"
                                value={endTime}
                                onChange={(e) => setEndTime(e.target.value)}
                            />
                        </Form.Group>
                    </div>
                    <Form.Group className="mb-3">
                        <Form.Label htmlFor="shift-notes">Notes</Form.Label>
                        <Form.Control

                            style={{ height: 38 }}
                            type="text"
                            id="shift-notes"
                            placeholder="Type any shift notes here"
                            value={notes}
                            onChange={(e) => setNotes(e.target.value)}
                        />
                    </Form.Group>

                    <Button variant="primary w-100" style={{ height: 38 }} onClick={handleSubmit}>
                        Create Shifts
                    </Button>
                </Form>
            </div>
        );
    };

    const handleFilterChange = (key, value) => {
        setTopFiltervals(prevState => ({ ...prevState, [key]: value }));
        if (key === 'shiftStart') {
            GetRoster(value, TopFiltervals.shiftFinish, selectedSite);
            GetRosterSummary(value, TopFiltervals.shiftFinish, TopFiltervals.site_name);

        //    setNewWeekCommencing(value);
        }
        else if (key === 'shiftFinish') {
            GetRoster(TopFiltervals.shiftStart, value, selectedSite);
            GetRosterSummary(TopFiltervals.shiftStart, value, TopFiltervals.site_name);
     
        //    setNewWeekEnding(value);
        }
        else if (key === 'site_name') {
            GetRoster(TopFiltervals.shiftStart, TopFiltervals.shiftFinish, value);
            GetRosterSummary(TopFiltervals.shiftStart, TopFiltervals.shiftFinish, value);
        }

    };

    const dateTimeRenderer = ({ cellData }) => {
        const date = new Date(cellData);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear().toString().slice(-2); // Last two digits of the year
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        return <span>{`${day}/${month}/${year} ${hours}:${minutes}`}</span>;
    };

    const dateRenderer = ({ cellData }) => {
        const date = new Date(cellData);
        const formattedDate = date.toLocaleDateString('en-GB'); // Format to dd/mm/yyyy
        return <span>{formattedDate}</span>;
    };

    const editableRenderer = ({ rowData }) => {
        return (
            <>
                {!rowData.editable && <FaLock />}
            </>
        );
    };
    const cache = new CellMeasurerCache({
        defaultWidth: 100,
        minWidth: 75,
        fixedHeight: true
    });

    const staffNameRenderer = ({ cellData }) => {
        const backgroundColor = cellData === 'UNCONFIRMED' ? '#B52776' : 'transparent';
        const color = cellData === 'UNCONFIRMED' && 'white';
        const style = {
            backgroundColor,
            color,
            padding: '10px',
            width: '113%',
            margin: '-10px'
        };

        return (
            <div style={style}>
                {cellData}
            </div>
        );
    };

    const rateRenderer = ({ rowData }) => {
        return (
            <p>&pound;{rowData.billRate.toFixed(2)}</p>
        );
    };

    const handleSiteSelect = (siteName, venueID, coDb) => {
        setSelectedSite(siteName);
        handleFilterChange('site_name', siteName);
        setcreateshiftvals(prevs => ({ ...prevs, venueid: venueID, codb: coDb }));
    };

    const MobileTable = ({ mobileData, anycheckbox_cheked }) => {
        const [showDupModal, setShowDupModal] = useState(false);
        const [showRemoveModal, setShowRemoveModal] = useState(false);
        const [checkedRows, setCheckedRows] = useState({});
        const [duplicateDate, setDuplicateDate] = useState(null);
        const [duplicateFinancialWeek, setDuplicateFinancialWeek] = useState({ description: 'Financial Week' });
        const handleCloseRemoveModal = () => setShowRemoveModal(false);
        const handleCloseDupModal = () => setShowDupModal(false);
        const [selectAll, setSelectAll] = useState(false);

        const TableOptions = () => {

            const handleRemove = () => {
                setShowRemoveModal(true);
            };

            const handleDuplicate = () => {
                setShowDupModal(true);
            };

            return (
                <div className="w-100 justify-content-end mb-3">
                    {isButtonDisabled &&
                        <Toast bg='dark' className="me-3 mb-3">
                            <Toast.Body><FaLock /> Padlocked rows cannot be removed, only duplicated</Toast.Body>
                        </Toast>
                    }
                    <div className="d-flex flexgrow-1">
                        <Button variant="primary me-3 flex-grow-1" style={{ maxHeight: 45 }} onClick={handleDuplicate}>
                            Duplicate Shifts
                        </Button>
                        <Button
                            disabled={isButtonDisabled}
                            style={{ maxHeight: 45 }}
                            variant="primary flex-grow-1" onClick={handleRemove}
                        >
                            Remove Shifts
                        </Button>
                    </div>
                </div>
            )
        };


        const [isButtonDisabled, setIsButtonDisabled] = useState(false);

        const handleCheckboxChange = (index) => {
            setCheckedRows((prevState) => {
                const updatedRows = { ...prevState };
                if (!!updatedRows[index]) {
                    delete updatedRows[index];
                } else {
                    updatedRows[index] = manageSec[index];
                }
                return updatedRows;
            });
        };

        const handleSelectAllToggle = () => {
            if (selectAll) {
                setCheckedRows({}); // Deselect all
            } else {
                const newCheckedRows = {};
                mobileData.forEach((_, index) => {
                    newCheckedRows[index] = mobileData[index];
                });
                setCheckedRows(newCheckedRows); // Select all
            }
            setSelectAll(!selectAll); // Toggle the button state
        };

        useEffect(() => {
            if (duplicateFinancialWeek.weekCommencing) {
                const date = typeof duplicateFinancialWeek.weekCommencing === 'string' ? new Date(duplicateFinancialWeek.weekCommencing) : duplicateFinancialWeek.weekCommencing;
                const formattedDate = date.toISOString().split('T')[0];

                setDuplicateDate(formattedDate);
            }
        }, [duplicateFinancialWeek]);

        useEffect(() => {
            const shouldDisableButton = Object.values(checkedRows).some(row => row.editable === false);
            setIsButtonDisabled(shouldDisableButton);
        }, [checkedRows]);

        // reset the financial week state if the datepicker is manually changed
        const handleDateChange = (e) => {
            setDuplicateDate(e.target.value);
            setDuplicateFinancialWeek({});
        }

        // Used for calculating the height of a card based on its content
        const cache = new CellMeasurerCache({
            defaultHeight: 410,
            fixedWidth: true
        });
        const checkboxRenderer = ({ rowIndex, isChecked, onChange }) => (
            <div className="form-check">
                <input
                    type="checkbox"
                    className="form-check-input"
                    id={`checkbox-${rowIndex}`} // Unique ID for each checkbox
                    name={`checkbox-${rowIndex}`} // Unique name for each checkbox
                    checked={isChecked}
                    onChange={() => onChange(rowIndex)}
                />
            </div>
        );
        const formatDate = (dateString) => {
            const options = { day: '2-digit', month: '2-digit', year: '2-digit' };
            return new Intl.DateTimeFormat('en-GB', options).format(new Date(dateString));
        };

        const formatDateTime = (dateTimeString) => {
            const options = {
                day: '2-digit',
                month: '2-digit',
                year: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
            };
            return new Intl.DateTimeFormat('en-GB', options).format(new Date(dateTimeString));
        };

        function rowRenderer({
            key, // Unique key within array of rows
            index, // Index of row within collection
            style,
            parent,
        }) {
            const rowData = mobileData[index]; // Get the specific object for the current row

            // Check if rowData exists
            if (!rowData) {
                return null; // Return null if rowData is undefined
            }

            const { staffName, roleName, billRate, weekEnding, shiftStart, shiftFinish, notes, editable } = rowData;
            return (
                <CellMeasurer
                    cache={cache}
                    columnIndex={0}
                    key={key}
                    parent={parent}
                    rowIndex={index}
                >
                    {({ measure, registerChild }) => (
                        <div className="mobile-card p-3 mb-3" ref={registerChild} onLoad={measure}>
                            <div className="header d-flex w-100 align-items-center justify-content-between">
                                {checkboxRenderer({
                                    rowIndex: index,
                                    isChecked: !!checkedRows[index],
                                    onChange: handleCheckboxChange
                                })}
                                {!editable && <FaLock />}
                            </div>
                            <div className="mobile-body">
                                <p>Staff Name: <span>{staffName}</span></p>
                                <p>Role: <span>{roleName}</span></p>
                                {/*<p>Rate: <span>&pound;{billRate.toFixed(2)}</span></p>*/}
                                <p>Week ending: <span>{formatDate(weekEnding)}</span></p>
                                <p>Start: <span>{formatDateTime(shiftStart)}</span></p>
                                <p>Finish: <span>{formatDateTime(shiftFinish)}</span></p>
                                <p>Notes: <span>{notes}</span></p>
                            </div>
                        </div>
                    )}
                </CellMeasurer>
            );
        }

        return (

            <>
                <Toast onClose={() => setToastVisible(false)} show={toastVisible} delay={5000} autohide bg='dark' className="mb-3">
                    <Toast.Header>
                        <strong className="me-auto">{toastTitle}</strong>
                    </Toast.Header>
                    <Toast.Body>{toastMessage}</Toast.Body>
                </Toast>
                <Modal show={showDupModal} onHide={handleCloseDupModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Select start date of new pattern.</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group className="">
                            <Form.Control
                                type="date"
                                id="duplicate-shifts"
                                value={duplicateDate}
                                onChange={handleDateChange}
                            />
                        </Form.Group>
                        <Dropdown className="ms-md-3">

                            <Dropdown.Toggle className="w-100 mb-3 mb-md-0 text-dark" variant="default" id="dropdown-basic">
                                {duplicateFinancialWeek.description || 'Financial Week'}
                            </Dropdown.Toggle>

                            <Dropdown.Menu style={{ maxHeight: 350 }}>
                                {financialWeeks.map((fwItem, index) => (
                                    <Dropdown.Item
                                        key={index}
                                        onClick={() => { setDuplicateFinancialWeek(fwItem) }}
                                    >{fwItem.description}</Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseDupModal}>
                            Cancel
                        </Button>
                        <Button variant="primary" onClick={async () => { await handleDuplicateSelected(duplicateDate, checkedRows, handleCloseDupModal); }} >
                            Duplicate
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={showRemoveModal} onHide={handleCloseRemoveModal} className="text-center">
                    <Modal.Header closeButton>
                        <Modal.Title>Are you sure you would like to delete selected shifts?</Modal.Title>
                    </Modal.Header>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseRemoveModal}>
                            Cancel
                        </Button>
                        <Button variant="primary danger" onClick={async () => { await handleOpenRemoveModal(checkedRows, handleCloseRemoveModal) }}>
                            Confirm
                        </Button>
                    </Modal.Footer>
                </Modal>
                {Object.keys(checkedRows).length > 0 && < TableOptions />}
                <div className="w-100 justify-content-end mb-3">
                    <Button
                        variant="primary me-3 flex-grow-1"
                        style={{ maxHeight: 45 }}
                        onClick={handleSelectAllToggle}
                    >
                        {selectAll ? 'Deselect All' : 'Select All'}
                    </Button>
                </div>
                <div className="mobile-table">
                    {mobileData.map((data, index) => (
                        rowRenderer({ key: data.id, index, style: {}, parent: null })
                    ))}
                </div>
            </>
        );
    };

    const DesktopTable = ({ rowData }) => {
        const [selectAll, setSelectAll] = useState(false);
        const [showDupModal, setShowDupModal] = useState(false);
        const [showRemoveModal, setShowRemoveModal] = useState(false);
        const [checkedRows, setCheckedRows] = useState({});
        const [duplicateDate, setDuplicateDate] = useState(null);
        const [duplicateFinancialWeek, setDuplicateFinancialWeek] = useState({ description: 'Financial Week' });
        const handleCloseRemoveModal = () => setShowRemoveModal(false);
        const handleCloseDupModal = () => setShowDupModal(false);
        const TableOptions = () => {

            const handleRemove = () => {
                setShowRemoveModal(true);
            };

            const handleDuplicate = () => {
                setShowDupModal(true);
            };

            return (
                <div className="d-flex w-100 justify-content-end align-items-center mb-3">
                    {isButtonDisabled &&
                        <Toast bg='dark' className="me-3 mb-0">
                            <Toast.Body><FaLock /> Padlocked rows cannot be removed, only duplicated</Toast.Body>
                        </Toast>
                    }
                    <Button variant="primary me-3" style={{ maxHeight: 45 }} onClick={handleDuplicate}>
                        Duplicate Selected
                    </Button>
                    <Button
                        disabled={isButtonDisabled}
                        style={{ maxHeight: 45 }}
                        variant="primary" onClick={handleRemove}
                    >
                        Remove Selected Shifts
                    </Button>
                </div>
            )
        };

        const [isButtonDisabled, setIsButtonDisabled] = useState(false);

        const handleSelectAllChange = () => {
            const newSelectAll = !selectAll;
            const newCheckedRows = {};

            if (newSelectAll) {
                manageSec.forEach((row, index) => {
                    newCheckedRows[index] = row; // Store the entire row data
                });
            }

            setSelectAll(newSelectAll);
            setCheckedRows(newCheckedRows);
        };

        const selectAllCheckboxRenderer = () => (
            <div className="form-check" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <input
                    type="checkbox"
                    className="form-check-input"
                    checked={selectAll}
                    onChange={handleSelectAllChange}
                />
            </div>
        );

        const handleCheckboxChange = (index) => {
            setCheckedRows((prevState) => {
                const updatedRows = { ...prevState };
                if (!!updatedRows[index]) {
                    delete updatedRows[index];
                } else {
                    updatedRows[index] = manageSec[index]; // Store the entire row data
                }

                const allChecked = Object.keys(updatedRows).length === manageSec.length;
                setSelectAll(allChecked);

                return updatedRows;
            });
        };

        const checkboxRenderer = ({ rowIndex }) => {
            return (
                <div className="form-check" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <input
                        type="checkbox"
                        className="form-check-input"
                        id={`checkbox-${rowIndex}`}
                        checked={!!checkedRows[rowIndex]}
                        onChange={() => handleCheckboxChange(rowIndex)}
                    />
                </div>
            );
        };

        // setting the value of the duplicate date based on the week commencing date
        useEffect(() => {
            if (duplicateFinancialWeek.weekCommencing) {
                const date = typeof duplicateFinancialWeek.weekCommencing === 'string' ? new Date(duplicateFinancialWeek.weekCommencing) : duplicateFinancialWeek.weekCommencing;
                const formattedDate = date.toISOString().split('T')[0];

                setDuplicateDate(formattedDate);
            }
        }, [duplicateFinancialWeek]);

        // reset the financial week state if the datepicker is manually changed
        const handleDateChange = (e) => {
            setDuplicateDate(e.target.value);
            setDuplicateFinancialWeek({});
        }


        useEffect(() => {
            const shouldDisableButton = Object.values(checkedRows).some(row => row.editable === false);
            setIsButtonDisabled(shouldDisableButton);
        }, [checkedRows]);



        return (
            <>
                <LoadingSpinner loading={loading} />
                <Toast onClose={() => setToastVisible(false)} show={toastVisible} delay={5000} autohide bg='dark'>
                    <Toast.Header>
                        <strong className="me-auto">{toastTitle}</strong>
                    </Toast.Header>
                    <Toast.Body>{toastMessage}</Toast.Body>
                </Toast>
                <Modal show={showDupModal} onHide={handleCloseDupModal} className="text-center">
                    <Modal.Header closeButton>
                        <Modal.Title>Select start date of new pattern.</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group className="">
                            <Form.Control
                                type="date"
                                id="duplicate-shifts"
                                value={duplicateDate}
                                onChange={handleDateChange}
                            />
                        </Form.Group>
                        <Dropdown className="ms-md-3">

                            <Dropdown.Toggle className="w-100 mb-3 mb-md-0 text-dark" variant="default" id="dropdown-basic">
                               {duplicateFinancialWeek.description || 'Financial Week'}
                            </Dropdown.Toggle>

                            <Dropdown.Menu style={{ maxHeight: 350}}>
                                {financialWeeks.map((fwItem, index) => (
                                    <Dropdown.Item
                                        key={index}
                                        onClick={() => { setDuplicateFinancialWeek(fwItem) }}
                                    >{fwItem.description}</Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseDupModal}>
                            Cancel
                        </Button>
                        <Button variant="primary" onClick={async () => { await handleDuplicateSelected(duplicateDate, checkedRows, handleCloseDupModal); }} >
                            Duplicate
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={showRemoveModal} onHide={handleCloseRemoveModal} className="text-center">
                    <Modal.Header closeButton>
                        <Modal.Title>Are you sure you would like to delete selected shifts?</Modal.Title>
                    </Modal.Header>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseRemoveModal}>
                            Cancel
                        </Button>
                        <Button variant="primary danger" onClick={async () => { await handleOpenRemoveModal(checkedRows, handleCloseRemoveModal) }}>
                            Confirm
                        </Button>
                    </Modal.Footer>
                </Modal>
                {noRosterDataMsg ? <div className="table-message">{noRosterDataMsg}</div> : (
                    <>
                        {Object.keys(checkedRows).length > 0 && <TableOptions />}
                        <AutoSizer disableHeight>
                            {({ width, height }) => (
                                <Table
                                    width={width}
                                    height={400}
                                    headerHeight={20}
                                    rowHeight={42}
                                    rowCount={manageSec.length}
                                    rowGetter={({ index }) => manageSec[index]}
                                    rowClassName={"mangerTableRow"}
                                >
                                    <Column
                                        maxWidth={45}
                                        minWidth={45}
                                        width={45}
                                        label={selectAllCheckboxRenderer()}
                                        dataKey="checkbox"
                                        cellRenderer={checkboxRenderer}
                                        headerRenderer={selectAllCheckboxRenderer}
                                    />
                                    <Column
                                        maxWidth={45}
                                        minWidth={45}
                                        width={45}
                                        label=""
                                        dataKey="editable"
                                        cellRenderer={editableRenderer}
                                    />
                                    <Column
                                        maxWidth={100}
                                        minWidth={100}
                                        width={100}
                                        label="Wk Ending"
                                        dataKey="weekEnding"
                                        cellRenderer={dateRenderer}
                                    />
                                    <Column
                                        maxWidth={125}
                                        minWidth={125}
                                        width={125}
                                        label="Start"
                                        dataKey="shiftStart"
                                        cellRenderer={dateTimeRenderer}
                                    />
                                    <Column
                                        maxWidth={125}
                                        minWidth={125}
                                        width={125}
                                        label="Finish"
                                        dataKey="shiftFinish"
                                        cellRenderer={dateTimeRenderer}
                                    />
                                    <Column
                                        maxWidth={180}
                                        minWidth={180}
                                        width={180}
                                        label="Role"
                                        dataKey="roleName"
                                        style={{ whiteSpace: 'break-spaces'}}
                                    />
                                    <Column
                                        width={220}
                                        minWidth={220}
                                        flexGrow={1}
                                        label="Staff Name"
                                        dataKey="staffName"
                                        style={{ whiteSpace: 'break-spaces', flex: '1 1 auto' }}
                                        cellRenderer={staffNameRenderer}
                                    />
                                    {/* <Column
                                    flexGrow={1}
                                    maxWidth={70}
                                    minWidth={70}
                                    width={70}
                                    label="Rate"
                                    dataKey="billRate"
                                    cellRenderer={rateRenderer}
                                /> */}
                                    <Column
                                        minWidth={500}
                                        flexGrow={1}
                                        flexShrink={1}
                                        label="Notes"
                                        dataKey="notes"
                                        style={{ whiteSpace: 'nowrap', textWrap: 'wrap' }}
                                    />
                                </Table>
                            )}
                        </AutoSizer>
                    </>
                )}
            </>
        )
    }



    const DesktopTableRosterSummary = () => {

        const transformData = (data) => {
            const columns = [""]; // First column for row labels
            const currentRow = { "": { value: "Current" } };
            const previousYearRow = { "": { value: "Previous Year" } };

            data.forEach(item => {
                columns.push(item.dateTimeDescription);
                currentRow[item.dateTimeDescription] = { value: item.shifts, cellColour: item.cellColour };
                previousYearRow[item.dateTimeDescription] = { value: item.previousYear };
            });

            return { columns, rows: [currentRow, previousYearRow] };
        };

        const { columns, rows } = transformData(rosterSummary);

        return (
            <div style={{ display: 'grid', gridTemplateColumns: `repeat(${columns.length}, 1fr)`, fontSize: '14px' }}>
                {/* Header Row */}
                {columns.map((column, index) => (
                    <div
                        key={index}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: '9px',
                            backgroundColor: '#4A4A4A',
                            fontWeight: 'bold',
                            textAlign: 'center',
                            whiteSpace: 'nowrap'
                        }}>
                        {column}
                    </div>
                ))}

                {/* Data Rows */}
                {rows.map((row, rowIndex) => (
                    columns.map((column, colIndex) => {
                        const cellData = row[column];
                        return (
                            <div
                                key={`${rowIndex}-${colIndex}`}
                                style={{
                                    backgroundColor: cellData?.cellColour || 'white',
                                    color: cellData?.cellColour ? 'white' : 'black',
                                    padding: '10px',
                                    display: 'flex',
                                    borderLeft: '1px solid #ddd',
                                    borderBottom: '1px solid #ddd',
                                    borderColor: '#ddd',
                                    alignItems: 'center',
                                    justifyContent: colIndex === 0 ? 'left' : 'center', // Center cells except first column
                                    whiteSpace: colIndex === 0 ? '' : 'null',
                                    textAlign: colIndex === 0 ? '' : 'center',
                                    wordWrap: colIndex === 0 ? '' : 'break-word'
                                }}>
                                {cellData?.value}
                            </div>
                        );
                    })
                ))}
            </div>
        );
    };

    const handleFinancialFromTo = (financeWeek) => {

        setSelectedFinancialWeek(financeWeek);

        const formatLocalDate = (dateString) => {
            const date = new Date(dateString);
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based, hence the +1
            const day = String(date.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        };

        const formattedStartDate = formatLocalDate(financeWeek.weekCommencing);
        const formattedEndDate = formatLocalDate(financeWeek.weekEnding);

        setTopFiltervals(prevState => ({
            ...prevState,
            shiftStart: formattedStartDate,
            shiftFinish: formattedEndDate
        }));

        GetRoster(formattedStartDate, formattedEndDate, selectedSite);
      
        GetRosterSummary(formattedStartDate, formattedEndDate, selectedSite);
    }

    return (

        <div className="manage-security-container">
            <Row>
                <Col md='12' lg='9'>
                    {/* Tables column */}
                    <div className="d-lg-flex align-items-end justify-content-between">
                        <div className="left-controls">
                            <div className="site-selection flex-wrap d-md-flex mb-lg-0">
                                {/* Drop down only visible if the user has more than 1 site */}
                                <Dropdown className="me-md-3 mb-md-3">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <Form.Label>Site</Form.Label>
                                    </div>

                                    <Dropdown.Toggle className="w-100 mb-3 mb-md-0" variant="default" id="dropdown-basic">
                                        {/* Map each site within here */}
                                        <div>{selectedSite}</div>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        {sites.map((siteItem, index) => (

                                    <Dropdown.Item
                                        key={index}
                                        // active={selectedSite === TopFiltervals.site_name}
                                        onClick={() => handleSiteSelect(siteItem.site_name, siteItem.venueID, siteItem.codb)}
                                    >{siteItem.site_name}</Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>
                        <div className="d-flex justify-content-evenly flex-grow-1 mb-0 mb-3 mb-md-0">
                            <Form.Group className="me-md-3 flex-grow-1 me-3 me-md-0">
                                <Form.Label htmlFor="global-shift-date-from">From</Form.Label>
                                <Form.Control
                                    style={{ height: 38 }}
                                    type="date"
                                    id="global-shift-date-from"
                                    value={TopFiltervals.shiftStart || ''}
                                    onChange={(event) => handleFilterChange('shiftStart', event.target.value)}
                                />
                            </Form.Group>

                                    <Form.Group className="me-md-3 flex-grow-1">
                                        <Form.Label htmlFor="global-shift-date-to">To</Form.Label>
                                        <Form.Control
                                            style={{ height: 38 }}
                                            type="date"
                                            id="global-shift-date-to"
                                            value={TopFiltervals.shiftFinish || ''}
                                            onChange={(event) => handleFilterChange('shiftFinish', event.target.value)}
                                        />
                                    </Form.Group>
                                    <Dropdown className="me-md-3">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <Form.Label>Financial Week</Form.Label>
                                        </div>

                                        <Dropdown.Toggle className="w-100 mb-3 mb-md-0" variant="default" id="dropdown-basic">
                                            <div>{selectedFinancialWeek.description}</div>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            {financialWeeks.map((fwItem, index) => (
                                                <Dropdown.Item
                                                    key={index}
                                                    onClick={() => handleFinancialFromTo(fwItem)}
                                                >{fwItem.description}</Dropdown.Item>
                                            ))}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                            {/*<div className="table-controls mt-3">*/}
                            {/*    <Button variant="primary me-3" onClick={toggleAddShift}>*/}
                            {/*        {addShift ? 'Hide Shift panel' : 'Add Shifts'}*/}
                            {/*    </Button>*/}
                            {/*    <Button variant="primary">*/}
                            {/*        Duplicate Selected*/}
                            {/*    </Button>*/}
                            {/*</div>*/}
                        </div>
                        <div className="statistics-right mb-md-3 px-4 py-2">
                            <div className="d-flex align-items-center justify-content-between">
                                <p className="me-5 d-flex">Total hours <span className="d-none d-lg-block" style={{marginLeft: 5}}> in selected period:</span></p><p>{totalHours}</p>
                            </div>
                            {/*<div className="d-flex align-items-center justify-content-between">*/}
                            {/*    <p>Total cost in selected period:</p><p>&pound;{totalCost}</p>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                    <div>
                        <div className="manager-reports-container d-flex flex-column">
                            {loading ? (
                                <div className="small-spinner">
                                    <span></span><span></span><span></span><span></span>
                                </div>
                            ) : (
                                <div>
                                        <div className="report-table-container roster-main-table mt-3 pb-0" style={{ border: "none" }}>
                                        {isMobileOnly ? <MobileTable mobileData={manageSec} /> : <DesktopTable />}

                                    </div>
                                    <div className="report-table-container roster-table mt-3 pb-0" style={{ border: "none" }}>
                                        {!isMobile && !isTablet && <DesktopTableRosterSummary />}
                                    </div>
                                </div>
                            )}
                        </div>     
                    </div>
                </Col>
                <Col md='12' lg='3'>
                    {/* Add shift column */}
                    <div className="add-shift-row w-auto p-3">
                        <AddShiftRow />
                    </div>
                </Col>
            </Row>
        </div>


    );
};

export default ManageSecurity;