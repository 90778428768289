import { React } from "react";
import EventControlLogin from './EventControlLogin';
import EventControlTable from './EventControlTable';
import Container from 'react-bootstrap/Container';
import './eventcontrol.scss';

function EventControl() {
    return ( 
        <Container fluid className='event-control'>
            <EventControlTable/>
        </Container>
    );
};

export default EventControl;