import { React } from "react";
import {Container} from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Logo from '../../images/events/logo.png';
import Title from '../../images/events/control-suite-title.svg';
import FooterLogos from '../../images/events/footer-logos.svg';

function EventControlLogin() {
    return ( 
        <Container style={{
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'center', 
            height: '100%'}}>
                <div md={12} style={{
                    flex: '0', 
                    textAlign: 'center'}}>
                    <img src={Logo}/>
                    <img className="my-4" src={Title}/>
                    <Button variant="primary">
                        Get started
                    </Button>
                    <div className="login-footer">
                        <img src={FooterLogos}/>
                    </div>
                </div>
        </Container>
)};

export default EventControlLogin;